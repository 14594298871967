import Loading from '@/components/loading';
import { axiosClient } from '@/data/axios';
import { auth } from '@/firebase/client';
import { Room, useInfiniteLoadMessages, useUpdateMessage } from '@/firebase/firestore';
import useUserInfo from '@/providers/auth/useUserInfo';
import { chatSettingsAtom, clientsMapAtom, staffsMapAtom, unreadMessagesCountAtom } from '@/utils/atoms';
import { urlSearchParams } from '@/utils/urlSearchParams';
import { Box } from '@mui/material';
import { useAtom } from 'jotai/index';
import { useRouter } from 'next/router';
import React, { forwardRef, MutableRefObject, Ref, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAsyncEffect } from 'rooks';
import ChatHeader from './fragments/header';
import MessageItem from './fragments/messageItem';

interface ChatMessagesProps {
	room: Room,
	clientView: boolean
}

const ChatMessages = forwardRef<HTMLDivElement, ChatMessagesProps>( ( {
	room,
	clientView,
}, ref: Ref<HTMLDivElement> ) => {
	const { user, staff } = useUserInfo();
	const router = useRouter();
	const [ clientsAtom ] = useAtom( clientsMapAtom );
	const { updateMessage } = useUpdateMessage( room.id );
	
	const [ chatSettings ] = useAtom( chatSettingsAtom );
	const [ , setUnreadMessagesCount ] = useAtom( unreadMessagesCountAtom );
	const [ staffsAtom ] = useAtom( staffsMapAtom );
	
	const { messages, hasMore, fetchNextPage } = useInfiniteLoadMessages( room.id );
	
	const [ isInitialLoad, setIsInitialLoad ] = useState( true );
	const [ unreadMessages, setUnreadMessages ] = useState( [] );
	const [ editId, setEditId ] = useState( '' );
	const [ value, setValue ] = useState( '' );
	
	const currentUserId = auth.currentUser?.uid;
	
	useEffect( () => {
		const currentElement = ( ref as MutableRefObject<HTMLDivElement | null> )?.current;
		
		if ( currentElement && isInitialLoad ) {
			const { scrollHeight, clientHeight } = currentElement;
			currentElement.scrollTop = scrollHeight - clientHeight;
			setIsInitialLoad( false );
		}
		setUnreadMessages( messages?.filter( ( message ) => !message.readBy?.includes( currentUserId ) ) );
	}, [ messages ] );
	
	useAsyncEffect( async () => {
		// if we are viewing this chat, mark messages as read
		if ( router.query.id === room.id && unreadMessages?.length > 0
			|| chatSettings.chatRoomID === room.id && chatSettings.windowChat === true && chatSettings.minimizeChat === false && unreadMessages?.length > 0 ) {
			await Promise.all( unreadMessages.map( ( message ) => axiosClient.post( '/api/user/chat/markMessageRead', {
				roomId   : room.id,
				messageId: message.id,
				uid      : currentUserId,
			} ) ) );
			const { data } = await axiosClient.post(
				`/api/user/chat/countUnreads?${urlSearchParams( {
					uid      : auth.currentUser?.uid,
					companyId: clientView ? null : room.company,
				} )}`,
			);
			setUnreadMessagesCount( data.unreadMessages );
		}
	}, [ unreadMessages, router.query.id ] );
	
	return (
		<Box
			ref={ref}
			id='scrollableDiv'
			sx={{ p: 1 }}
			style={{
				overflowY    : 'auto',
				overflowX    : 'hidden',
				display      : 'flex',
				flexGrow     : 1,
				flexDirection: 'column-reverse',
			}}>
			<InfiniteScroll
				inverse
				hasMore={hasMore}
				scrollThreshold={0.5}
				dataLength={messages.length}
				next={fetchNextPage}
				loader={<Loading/>}
				scrollableTarget='scrollableDiv'>
				{messages.map( ( message, index ) => (
					<MessageItem
						key={message.id}
						room={room}
						message={message}
						index={index}
						messages={messages}
						clientView={clientView}
						user={user}
						staff={staff}
						clientsAtom={clientsAtom}
						staffsAtom={staffsAtom}
						editId={editId}
						setEditId={setEditId}
						value={value}
						setValue={setValue}
						hasMore={hasMore}
						updateMessage={updateMessage}
					/>
				) )}
			</InfiniteScroll>
			{!hasMore && <ChatHeader room={room} clientView={clientView}/>}
		</Box>
	);
} );

export default ChatMessages;
