import useUserInfo from '@/providers/auth/useUserInfo';
import { Avatar, AvatarGroup, Box, ListItem, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import { useAtom } from 'jotai/index';
import React from 'react';
import { Room, useRoomMessages } from '../../../../firebase/firestore';
import safeDateFns from '../../../../helpers/safeFormat';
import { clientsMapAtom, commercesMapAtom, staffsMapAtom } from '../../../../utils/atoms';
import { transformFirebaseDate } from '../utils';
import BadgedAvatar from './fragments/avatar';

export default function ChatTopBar( {
	room,
	clientView = false,
}: {
	room: Room,
	clientView: boolean
} ) {
	const { user } = useUserInfo();
	const messages = useRoomMessages( room?.id, { limit: 1, order: 'desc' } ); // only 1 message
	const [ clientsAtom ] = useAtom( clientsMapAtom );
	const [ staffsAtom ] = useAtom( staffsMapAtom );
	const [ commercesAtom ] = useAtom( commercesMapAtom );
	
	const client = clientsAtom[ room?.client ];
	const staffs = room?.staffs ? room.staffs.map( ( x ) => staffsAtom[ x ] ).filter( Boolean ) : [];
	const commerce = commercesAtom[ room.commerceId ];
	const tooltip = commerce ? 'View commerce' : clientView ? 'Company' : 'Client';
	const title = clientView ? `${staffs?.[ 0 ]?.firstName} ${staffs?.[ 0 ]?.lastName}` : client?.name;
	const subtitle = commerce ? `${commerce?.type} ${commerce?.number}` : clientView
		? client?.email
		: staffs?.[ 0 ]?.email;
	
	const type = ( commerce, clientView ) => {
		switch ( commerce?.type ) {
			case 'ORDER':
				return clientView ? 'order' : 'orders';
			case 'ESTIMATE':
				return clientView ? 'estimate' : 'estimates';
			case 'INVOICE':
			default:
				return clientView ? 'invoice' : 'invoices';
		}
	};
	
	const linkToInvoice = room.commerceId
		? clientView
			? `/p/${room.commerceId}/${type( commerce, clientView )}`
			: `/dashboard/commerce/${type( commerce, clientView )}/${room.commerceId}`
		: '';
	
	return (
		<Stack
			direction='row'
			alignItems='center'
			justifyContent='space-between'
			sx={{
				p           : 1,
				borderBottom: 1,
				borderColor : 'divider',
				height      : 100,
				maxHeight   : 100,
				minHeight   : 100,
			}}>
			<Box sx={{ pl: 1 }}>
				{clientView
					? <BadgedAvatar key={user.id} user={user}/>
					: (
						<AvatarGroup
							max={4}
							sx={{
								'.MuiAvatar-root': { borderRadius: 2 },
								'alignSelf'      : 'start',
								'justifyContent' : 'start',
							}}>
							{staffs.map( ( staff, index ) => (
								<Avatar
									key={index}
									variant='rounded'
									src={staff.image}
									alt={staff.firstName}>
									{staff.firstName?.[ 0 ] || '' + staff.lastName?.[ 0 ] || ''}
								</Avatar>
							) )}
						
						</AvatarGroup>
					)}
				<Typography color='text.secondary'>
					Last
					message {safeDateFns.formatDistanceToNow( transformFirebaseDate( messages?.[ 0 ]?.createdAt || room.updatedAt ) )}
				</Typography>
			</Box>
			<Tooltip title={tooltip}>
				<Box>
					<ListItem>
						<ListItemText
							primary={title}
							primaryTypographyProps={{ variant: 'h5' }}
							secondary={subtitle}
							onClick={() => {
								if ( commerce ) {
									window.open( linkToInvoice, '_blank' );
								}
							}}
						/>
					</ListItem>
				</Box>
			</Tooltip>
		</Stack>
	);
}

